import debounce from 'lodash.debounce'

const OrderSummary = {
  el: '[data-component="order-summary"]',

  ui: {
    details: '[data-order-summary-details]',
    button: '[data-order-summary-button]'
  },

  // Because multiple number steppers can exist on the same page,
  // we need a way to track overall total addons/upsells
  quantity: 0,

  // For mobile checks
  mq: window.matchMedia('(min-width: 768px)'),

  initialize: function () {
    this.handleResize()
    this.addResizeListener()
    this.addNumberStepperListeners()
  },

  addResizeListener: function () {
    window.addEventListener('resize', debounce(() => this.handleResize(), 200),)
  },

  // Custom events from tne number stepper component, intended to help alter
  // the button label text depending on user interaction.
  addNumberStepperListeners: function () {
    window.addEventListener('numberStepperIncrement', this.handleIncrement.bind(this))
    window.addEventListener('numberStepperDecrement', this.handleDecrement.bind(this))
  },

  // present as closed for mobile to save space, expand on desktop for full view
  handleResize: function () {
    if (this.mq.matches) {
      this.ui.details[0].setAttribute('open', '')
    } else {
      this.ui.details[0].removeAttribute('open')
    }
  },

  handleIncrement: function () {
    this.quantity++
    this.handleQuantityChange();
  },

  handleDecrement: function () {
    this.quantity--
    this.handleQuantityChange();
  },

  // If the quantity changes, adjust the button text to the correct value
  handleQuantityChange: function () {
    if (this.quantity === 0) {
      this.ui.button[0].innerHTML = this.ui.button[0].dataset.labelNoQuantity
    } else if (this.quantity >= 0) {
      this.ui.button[0].innerHTML = this.ui.button[0].dataset.labelHasQuantity
    }
  }
}

export default OrderSummary;
