const SELECTED_CLASS = '-calendar-day-selected'

var Calendar = {
  ui: {
    dayInput: '[data-calendar-day-input]',
    monthInput: '[data-calendar-month-input]',
    selectButtons: '[data-calendar-selection]',
    submit: '[data-calendar-submit]',
    yearInput: '[data-calendar-year-input]',
    startAtInput: '[data-calendar-start-at]',
    endAtInput: '[data-calendar-end-at]'
  },

  selection: null,

  validated: false,

  events: {
    'click {selectButtons}': 'select',
  },

  initialize: function () {
    this.el.action = window.location.pathname + "/timed_tickets/choosetime"
    this.ui.dayInput[0].setCustomValidity('Please select a day.')
  },

  select: function (e) {
    const target = e.target

    const data = JSON.parse(JSON.stringify(target.dataset))

    const {
      calendarDay: day,
      calendarMonth: month,
      calendarYear: year,
    } = data


    if (!this.validated) {
      this.ui.dayInput[0].setCustomValidity('')
      this.validated = true
    }

    if (target !== this.selection) {
      if (this.selection) {
        this.selection.classList.remove(SELECTED_CLASS)
        this.selection.setAttribute('aria-checked', 'false')
      }

      this.selection = target
      this.selection.classList.add(SELECTED_CLASS)
      this.selection.setAttribute('aria-checked', 'true')
    }

    this.ui.yearInput[0].value = year
    this.ui.monthInput[0].value = month
    this.ui.dayInput[0].value = day

    const yearInt = parseInt(year)
    const monthInt = parseInt(month)
    const dayInt = parseInt(day)

    this.ui.startAtInput[0].value = this.formatDate(yearInt, monthInt, dayInt)
    this.ui.endAtInput[0].value = this.formatDate(yearInt, monthInt, dayInt + 1)

    this.ui.submit[0].focus()
  },

  formatDate: function (y, m, d) {
    let year = y
    let month = m
    let day = d

    // Because 1 is being added to the day on line 64, this if statement is necessary
    // to turn the current combination of year, month, and day into a valid date.
    if (d > 31 // Months have a maximum of 31 days.
      || ([4, 6, 9, 11].includes(m) && d > 30) // Apr (4), Jun (6), Sep (9), and Nov (11) all have only 30 days.
      || (m == 2 && d > 29) // Feb (2) has a maximum of 29 days.
      || (m == 2 && d > 28 && (y % 4 != 0 || (y % 100 == 0 && y % 400 != 0)))) { // Feb (2) has only 28 days if it is a leap year, which is when the year is divisible by 4, but not divisible by 100 unless also divisible by 400.
      day = 1

      if (m == 12) {
        year = y + 1
        month = 1
      } else {
        month = m + 1
      }
    }

    const monthStr = String(month).padStart(2, '0')
    const dayStr = String(day).padStart(2, '0')

    return `${year}-${monthStr}-${dayStr}`
  }
}

export default Calendar
