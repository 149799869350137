import * as htmx from 'htmx.org';
window.htmx = htmx;

const SELECTED_CLASS = '-calendar-day-selected'

var PricingProgramCalendar = {
  ui: {
    selectButtons: '[data-calendar-selection]',
    selectedButtons: '.-calendar-day-selected',
    selectTimeAreas: '[data-calendar-times]',
    calendar: '[data-calendar]',
    indicator: '[data-calendar-indicator]',
  },

  mql: window.matchMedia('(max-width: 768px)'),
  selection: null,
  summaryHeight: 0,
  footerHeight: 0,
  resizeObserver: null,
  timeout: null,
  footer: null,

  events: {
    'click {selectButtons}': 'select',
  },

  initialize: function () {
    if (this.ui.selectedButtons.length > 0) {
      this.selection = this.ui.selectedButtons[0]
    }

    this.measure()
    document.addEventListener("htmx:afterSwap", this.measure.bind(this), false);

    // simple debounce, if it fires again before it completes the timer is reset
    this.resizeObserver = new ResizeObserver(() => {
      window.clearTimeout(this.timeout)
      this.timeout = window.setTimeout(() => {
        this.measure()
      }, 250)
    })
    this.resizeObserver.observe(this.ui.selectTimeAreas[0])
  },

  // ensure mobile summary doesn't cover any actions or text
  measure: function () {
    this.summaryHeight = document.querySelector('[data-calendar-summary]').offsetHeight ?? 0;
    this.footer = document.querySelector('.footer');
    this.mql.matches ? this.footer.style.marginBottom = this.summaryHeight + "px" : this.footer.style.marginBottom = "0px";
  },

  select: function (e) {
    const target = e.target

    if (target !== this.selection) {
      if (this.selection) {
        this.selection.classList.remove(SELECTED_CLASS)
        this.selection.setAttribute('aria-checked', 'false')
      }

      this.selection = target
      this.selection.classList.add(SELECTED_CLASS)
      this.selection.setAttribute('aria-checked', 'true')
    }

    if (this.mql.matches) {
      this.ui.selectTimeAreas[0].scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
  }
}

export default PricingProgramCalendar
