const INVALID_MESSAGE = 'Please add at least one ticket.'

var SubtotalCalculator = {

  ui: {
    form: '[data-tickets-form-form]',
    lineItems: '[data-tickets-form-line-item]',
    submitButton: '[data-tickets-form-submit-button]',
    validityOutlet: '[data-tickets-form-validity-outlet]',
  },

  events: {
    'change {lineItems}': 'render',
    'click {submitButton}': 'submit',
  },

  state: { valid: false },

  initialize: function () {
    this.unitPrices = this.ui.lineItems.map(l => l.dataset.ticketsFormUnitPrice || 0)
    this.render()
  },

  render: function () {
    this.setValidity(false)

    const quantity = [...this.ui.lineItems]
      .map(item => Number(item.value))
      .reduce((acc, cur) => acc + cur)

    this.state.valid = quantity > 0

    this.setValidity(this.state.valid)
  },

  setValidity: function (valid) {
    const message = valid ? '' : INVALID_MESSAGE
    this.ui.validityOutlet[0].setCustomValidity(message)
  },

  submit: function (e) {
    if (!this.state.valid) {
      return
    }

    e.preventDefault()

    this.ui.submitButton[0].classList.add('btn-loading')
    this.ui.submitButton[0].disabled = true
    this.ui.form[0].submit()
  },
}

export default SubtotalCalculator
