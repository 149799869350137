/**
 * Shop
 * These pages can be found in the /shop zone.
 */

import '../src/sentry.js'

import Washi from 'washi'
import Checkout from '../src/tickets/views/checkout.js'
import PromotionModal from '../src/tickets/views/promotion-modal.js'
import CheckoutForm from '../src/shop/views/checkout-form.js'
import AddonForm from '../src/shop/views/add-on-form.js'
import MembershipUpsell from '../src/shop/views/membership-upsell.js'
import DonationForm from '../src/shop/views/donation-form.js'
import Order from '../src/tickets/models/order.js'
import TicketsForm from '../src/components/tickets-form.js'
import Countdown from '../src/components/countdown.js'
import Calendar from '../src/components/calendar.js'
import PricingProgramCalendar from '../src/components/pricing-program-calendar.js'
import CalendarModal from '../src/shop/views/calendar-modal.js'
import OrderSummary from '../src/shop/views/order-summary.js'

Washi(Checkout, { el: '[data-component=two-step-checkout]' })
Washi(Checkout, { el: '[data-component=multi-step-checkout]' })
Washi(AddonForm, { el: '[data-addon-form]' })
Washi(AddonForm, { el: '[data-ticket-form]' })
Washi(PromotionModal, {
  el: '[data-component=promotion-modal]',
  order: new Order()
})
Washi(CheckoutForm, { el: '[data-checkout-form]' })
Washi(MembershipUpsell, { el: '[data-membership-upsell]' })
Washi(DonationForm, { el: '[data-donation-form]' })
Washi(TicketsForm, { el: '[data-component=tickets-form' })
Washi(Countdown, { el: '[data-countdown]' })
Washi(Calendar, { el: '[data-component=calendar]' })
Washi(PricingProgramCalendar, { el: '[data-component=pricing-program-calendar]' })
Washi(CalendarModal, { el: '[data-component=calendar-modal]' })
Washi(OrderSummary, { el: '[data-component=order-summary]' })

// Submit the order on the processing order page
// TODO Henry: make this a data-component module with its own file
function submitButton() {
  var submitButton = document.querySelector('[data-component=order-submit-form]')
  if (submitButton) { submitButton.click() }
}

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", submitButton)
} else {
  submitButton();
}
